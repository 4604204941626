import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Route, Routes, useLocation } from 'react-router-dom';

import Footer from './components/Footer';
import Home from './pages/Home';
import WakaTime from './pages/WakaTime';
import CV from './pages/CV';

import './App.css';

const lightTheme = {
  background: 'rgba(255, 255, 255, 1)',
  text: 'rgba(51, 51, 51, 1)',
  title: 'rgba(239, 108, 0, 1)',
  tooltipBackground: 'white',
  tooltipBorder: 'black',
  tooltipText: '#000',
};

const darkTheme = {
  background: 'rgba(17, 17, 17, 1)',
  text: 'rgba(255, 255, 255, 1)',
  title: 'rgba(255, 183, 77, 1)',
  tooltipBackground: '#111',
  tooltipBorder: 'white',
  tooltipText: '#fff',
};

const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.background};
  transition: background-color 0.3s ease;
    display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const CVContainer = styled.div`
  background-color: #fff; /* Ensure the background is always white for CV */
  min-height: 100vh; /* Cover full height */
  display: flex;
  justify-content: center; /* Center the CVContainer */
  align-items: center; /* Center the CVContainer */
`;

function App({ isDarkMode, toggleTheme, switchLanguage, currentLanguage }) {
  const location = useLocation();

  return (
    <>
      {location.pathname === '/cv' ? (
        <CVContainer>
          <CV />
        </CVContainer>
      ) : (
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
          <AppContainer>
            <Routes>
              <Route 
                path="/" 
                element={<Home isDarkMode={isDarkMode} toggleTheme={toggleTheme} switchLanguage={switchLanguage} currentLanguage={currentLanguage} />} 
              />
              <Route 
                path="/wakatime" 
                element={<WakaTime isDarkMode={isDarkMode} toggleTheme={toggleTheme} switchLanguage={switchLanguage} currentLanguage={currentLanguage} />} 
              />
            </Routes>
            <Footer></Footer>
          </AppContainer>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
